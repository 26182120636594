import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const kontrol = async (mail, parola) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/kontrol`,
    json: {
      mail: mail,
      parola: parola,
    },
  });

  // return request(`${process.env.VUE_APP_API_URL}/kontrol`, {
  //   mail,
  //   parola,
  // });
};

export { kontrol };
