<template>
  <layout>
    <template slot="app-bar">
      <div style="display: none" />
    </template>
    <template slot="nav-bar">
      <div style="display: none" />
    </template>
    <template slot="main">
      <v-main>
        <v-container fluid style="height: 100vh" fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
              <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>NKOFFICE.v1</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-text-field
                      prepend-icon="mdi-account"
                      name="mail"
                      label="Kullanıcı Adı"
                      type="text"
                      v-model="mail"
                    ></v-text-field>
                    <v-text-field
                      id="password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Parola"
                      type="password"
                      v-model="password"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="kullaniciGirisKontrolu()">
                    Oturum Aç
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import { kontrol } from "../query/login";

export default {
  name: "Login",

  methods: {
    async hatali() {
      await this.$dialog.error({
        text: "Hatalı Şifre",
        title: "Dikkat",
        actions: {
          true: {
            color: "error",
            text: "Tamam",
          },
        },
      });
    },
    async kullaniciGirisKontrolu() {
      this.gelen_sonuc = await kontrol(this.mail, this.password);
      console.log("sonucc", this.gelen_sonuc.id);
      if (this.gelen_sonuc.id !== undefined) {
        localStorage.setItem("token", this.gelen_sonuc.id);
        this.$router.push({
          name: "AnaSayfa",
        });
      } else {
        this.hatali();
      }
    },
  },

  data() {
    return {
      gelen_sonuc: null,
      mail: null,
      password: null,
    };
  },

  components: {
    Layout,
  },
};
</script>
